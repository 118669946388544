{
  co2Calculator: "CO<sub>2</sub>&nbsp;Calculator",
  introduction: "With five factors you can quickly estimate your footprint in CO<sub>2</sub> equivalents (CO<sub>2</sub>e). Your footprint is interactively computed for each factor as well as overall. The sources for the estimation of each factor are linked and the source code is available on <a href='https: //github.com/JarnoRFB/planted-co2-calculator'>GitHub</a>. <br /> All options are preset to a big footprint. Find out how much CO<sub>2</sub> you already save and where you can still improve. Have fun!",
  tonsCarbon: "tons CO<sub>2</sub>e",
  result: "With a footprint of {totalEmissions} you are {relationToAverage} the German average of 11 tons. Compensate your footprint now, for example at <a href='https://planted.green?utm_source=calculator&utm_medium=press&utm_campaign=calculator_launch'>planted.green</a>.",
  relation: {
    farBelow: "far below",
    below: "below",
    above: "above",
    farAbove: "far above",
  },
  sources: "Sources",
  value: "Value",
  thisSourceIsValid: "This source is up to date.",
  thisSourceIsInvalid: "This source is no longer up to date.",
  shortHauls: "Short hauls",
  mediumHauls: "Medium hauls",
  longHauls: "Long hauls",
  kilometersPerWeek: "Kilometers per Week",
  householdSize: "People in your household",
  apartmentSize: "Apartment size",
  apartmentAge: "Year of construction",
  energySource: "Energy source",
  greenEnergy: "Green Energy",
  flying: {
    question: "How often did you fly in the last 12 month?",
  },
  driving: {
    question: "How many kilometers do you drive by car every week?",
  },
  housing: {
    question: "Heating and Electricity",
    type: "Type of housing",
    options: {
      oil: "Oil",
      naturalGas: "Gas",
      longDistanceHeating: "Long distance heating",
      heatingPump: "Heating pump",
      woodPellets: "Wood pellets",
      house: "House",
      apartment: "Apartment",
    },
  },
  nutrition: {
    question: "What does your diet look like?",
    options: {
      CARNIVORE: "Lots of meat",
      FLEXITARIAN: "Plant based",
      VEGETARIAN: "Vegetarian",
      VEGAN: "Vegan",
    },
  },
  consumerism: {
    question: "How do you shop?",
    options: {
      frugal: "Frugal",
      normal: "Normal",
      lush: "Lush",
    },
  },
}
