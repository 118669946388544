
import {defineComponent, PropType} from "vue"
import {SourceSchema} from "../lib/estimation/sources"
import SourceCitation from "./SourceCitation.vue"
import {useI18n} from "vue-i18n"

export default defineComponent({
  name: "SourceCitationList",
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: "global",
    })
    return {t}
  },
  props: {
    sources: {
      type: Object as PropType<Array<SourceSchema<any>>>,
      required: true,
    },
  },
  components: {SourceCitation},
})
