
import {LocalizedDescription, PublicationMetadata} from "@/lib/estimation/sources"
import {defineComponent, PropType} from "vue"
import {useI18n} from "vue-i18n"

export default defineComponent({
  name: "SourceCitation",
  setup() {
    const {t, locale} = useI18n({
      inheritLocale: true,
      useScope: "global",
    })
    return {t, locale}
  },
  props: {
    value: null,
    description: {type: Object as PropType<LocalizedDescription>, required: true},
    url: String,
    valid: Boolean,
    index: Number,
    publicationMetadata: {type: Object as PropType<PublicationMetadata>},
  },
  data() {
    return {
      popperOptions: {boundariesElement: "scrollParent"},
    }
  },
  computed: {
    jsonValue(): any {
      if (typeof this.value === "object") {
        return `<pre><code>${JSON.stringify(this.value, null, 2)}</code></pre>`
      } else {
        return this.value
      }
    },
    tooltipContent(): string {
      return this.valid ? this.t("thisSourceIsValid") : this.t("thisSourceIsInvalid")
    },
  },
})
